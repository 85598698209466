@import "colors";

/*Alle bildene i bildekarusellen må ha en viss størrelse*/
.carousel-inner {
	/*margin: auto;
    position: center;
    height: 50vw;*/
	max-height: 80vh;
	width: 100%;
}

.carousel-inner img {
	// object-fit: contain; /* Do not scale the image */
	// object-position: 50% 50%; /* Center the image within the element */
}

#bildekarusellStreker li {
	background-color: $yellow;
}

#ForsideKarusell {
}

@media only screen and (min-width: 1000px) {
	.carousel-inner img {
		height: 60vh;
		object-fit:cover; /* Do not scale the image */
		object-position: 50% 50%; /* Center the image within the element */
	}
}

// @media only screen and (max-width: 800px){
//     .carousel-inner{
//         height: 35vh;
//     }
// }
