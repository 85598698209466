@import "colors";

.Footer{
    background-color: $yellow;
    bottom: 0;
    padding: 0 20px 20px 20px;
    z-index: 3;
    width: 100%;
}
/*Lagede klasse for å gjøre hyperlinker sorte*/
.sortTekst{
    color: black;
}
.sortTekst:hover{
    color: black;
}


/* The container <div> - needed to position the dropup content */
.dropup {
position: relative;
display: inline-block;
}

/* Dropup content (Hidden by Default) */
.dropup-content {
display: none;
position: absolute;
bottom: 40px;
background-color: $yellow;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
}

/* Links inside the dropup */
.dropup-content a {
color: black;
padding: 5px 10px 5px 10px;
text-decoration: none;
display: block;
}

.dropup-content hr {
    margin: 5px;    
}


.dropup-content a:hover {
    background-color: black;
}
#footerProsjekt:hover{
    color: white;
}

/* Show the dropup menu on hover */
.dropup:hover .dropup-content {
display: block;
}

.dropup a {
    color: black;
}

/* Change the background color of the dropup button when the dropup content is shown */
.dropup:hover .dropbtn {
text-decoration: underline;
cursor: pointer;
}

@Media only screen and (max-width: 780px){
    #footerDropUp{
        padding-bottom: 5px;
    }
    .dropup-content {
        bottom: 27px;
    }
}
